module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Riddham Suvagiya Portfolio","short_name":"Riddhamr","description":"Hello, I'm Riddham Suvagiya, a Computer Science and Engineering enthusiast currently pursuing my MSc in the field at the University at Buffalo, NY, with an expected graduation in Jan 2024. I also hold a BTech in Information and Communication Technology from DAIICT, India, where I achieved a GPA of 7.86/10. With experience as a Software Engineer at Tekion, Bengaluru, and a Java Developer at Monocept @Idera, I've contributed to enhancing user experiences, optimizing dashboards, and upgrading encryption systems. I'm passionate about projects like 'Blockify,' a blockchain-based music player, and 'FoodCourt,' a social media startup. My skills span multiple programming languages and tech stacks, making me well-equipped for diverse projects. Explore my portfolio to learn more about my work, skills, and commitment to innovation in the world of technology.","start_url":"/","background_color":"#141821","display":"standalone","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
